import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import DfProperty from "@/vue/domain/content/df-property";
import Multiselect from "vue-multiselect";

const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    Multiselect,
  },
})
export default class DfPageFormMailComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private formFields: any = {};
  private formFieldsErrors: any = {};
  private formSendedSuccesful = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get formMail(): DfContent {
    const formMailAlias: string = this.$route.params.formMailAlias ? this.$route.params.formMailAlias.toString() : null;
    return this.$store.getters.brandContents.find((content: DfContent) => content.type.code === "FML" && content.alias === formMailAlias) || null;
  }

  get formTitle(): string {
    const propertyTitle: string = Utils.getPropertyValue(this.formMail, Utils.PROPERTY_TITLE, `STRING`);
    return propertyTitle ? propertyTitle : null;
  }

  get formText(): string {
    const propertyText: string = Utils.getPropertyValue(this.formMail, Utils.PROPERTY_TEXT, `TEXT`);
    return propertyText ? propertyText : null;
  }

  @Watch("formMail", { immediate: true })
  formMailChange() {
    document.title = this.formTitle ? `${this.formTitle} | ${Utils.APP_NAME}` : "";
    this.formFields = this.formMail
      ? this.formMail.properties
          .filter((property: DfProperty) => property.code.startsWith(Utils.PROPERTY_FORM_FIELD))
          .map((property: DfProperty) => {
            let value: any = property.values.length > 0 ? property.values[0] : null;
            value = value && property.type === "DATE" ? `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}` : value;
            return {
              code: property.code,
              label: property.label,
              type: property.type,
              value: value,
              options: property.values,
            };
          })
      : [];
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private loadFile(event: any, index: number) {
    this.formFields[index].value = event.target.files;
  }

  private submitForm() {
    const formData = new FormData();
    this.formFields.forEach((field: any) => {
      switch (field.type) {
        case "FILE":
          Array.from(field.value || []).forEach((file: any, index: number) => {
            formData.append(field.code, file);
          });
          break;
        case "LIST":
          formData.append(field.code, field.value.key);
          break;
        case "DATE":
          formData.append(field.code, field.value ? field.value.split("-").join("") : null);
          break;
        default:
          formData.append(field.code, field.value);
      }
    });

    this.service
      .sendFormMail({ formMailAlias: this.formMail.alias, formData: formData })
      .then((response: any) => {
        this.formSendedSuccesful = true;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private goBack() {
    Utils.goBack(this);
  }
}
